/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit";
import { cookiesService } from "../services/cookies.service";

const initialState = {
  items: [],
  itemsCount: 0,
  totalAmount: 0,
  selectedProduct: {},
  selectedProductQuantity: 1,
  deleteProduct: {}
};
const cartKey = 'cart'
const orderKey = 'order'
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    getAll: (state, { payload }) => {
      let x = cookiesService.get(cartKey) ?? []
      let y = [];
      console.log(payload.id);
      console.log('====================================');
      if (x.length > 0) {
        x.forEach((item) => {
          if (item.storeId == payload.id) {
            y.push(item)
          }
        })
      }
      state.items = y;
      state.itemsCount = 0
      state.totalAmount = 0
      state.items?.forEach((item) => {
        state.itemsCount += item.quantity;
        state.totalAmount += item.quantity * item.price
      })
    },
    increaseOneToCart: (state, { payload }) => {
      state.items?.forEach((item) => {
        if (item.id === payload.id) {
          item.quantity++
        }
      })
      cookiesService.edit(cartKey, state.items)
      state.totalAmount += payload.price
      state.itemsCount++
    },
    decreaseOneToCart: (state, { payload }) => {
      state.items?.forEach((item) => {
        if (item.id === payload.id && item.quantity > 1) {
          item.quantity--
        }
      })
      cookiesService.edit(cartKey, state.items)
      state.totalAmount -= payload.price
      state.itemsCount--
    },
    add: (state, { payload }) => {
      state.items.push(payload)
      cookiesService.add(cartKey, state.items)
      state.itemsCount = 0
      state.items?.forEach((item) => {
        state.itemsCount += item.quantity;
      })
    },
    setDeleteProduct: (state, { payload }) => {
      state.deleteProduct = payload
    },
    removefromCart: (state, { payload }) => {
      state.items = state.items?.filter((item) => item.id !== payload.id);
      cookiesService.edit(cartKey, state.items);
      state.itemsCount = 0
      state.totalAmount = 0
      state.items?.forEach((item) => {
        state.itemsCount += item.quantity;
        state.totalAmount += item.quantity * item.price;
      })
    },
    removeCart: (state) => {
      cookiesService.add(orderKey, state.items)
      cookiesService.remove(cartKey)
      state.items = [];
      state.itemsCount = 0;
      state.totalAmount = 0;
      state.selectedProduct = {};
      state.selectedProductQuantity = 1;
      state.deleteProduct = {}
    },
    removeOrder: (state) => {
      cookiesService.remove(orderKey)
    },
    setSelectedProduct: (state, { payload }) => {
      state.selectedProduct = payload;
    },
    setSelectedProductQuantity: (state, { payload }) => {
      state.selectedProductQuantity = payload;
    },
    increaseOne: (state) => {
      state.selectedProductQuantity++;
      state.itemsCount++
    },
    decreaseOne: (state) => {
      if (state.selectedProductQuantity > 1) {
        state.selectedProductQuantity--;
        state.itemsCount--
      }
    }
  }
});

export const { getAll, add, removefromCart, removeCart, removeOrder, setSelectedProduct, setSelectedProductQuantity, increaseOne, decreaseOne, setCardQuantities, setCardItemQuantity, increaseOneToCart, decreaseOneToCart, setDeleteProduct } = cartSlice.actions
export default cartSlice.reducer;
