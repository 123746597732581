import React from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../assets/imgs/logo.png';
import akwadLogo from '../../assets/imgs/Logo2.png'
import env from '../../env'
import { t } from 'i18next'
import ReactStars from "react-rating-stars-component";
import { selectStoreInfo } from "../../slices/storeInfoSlice";
import LanguageSwitcher from "../others/LanguageSwitcher";
import CheckPointsModal from "../modals/CheckPointsModal";

export default function MainLayout({ children }) {

    const { id, type } = useParams();
    const { itemsCount } = useSelector((state) => state.cart);
    const storeInfo = useSelector(selectStoreInfo);
    const [modalShow, setModalShow] = React.useState(false);

    //add class to navbar on scroll to make it sticky
    window.addEventListener("scroll", () => {
        let value = window.scrollY;
        if (value > 0) {
            if (document.getElementById("navbar")) {
                document.getElementById("navbar").classList.add("sticky");
            }
        } else {
            if (document.getElementById("navbar")) {
                document.getElementById("navbar").classList.remove("sticky");
            }
        }
    });

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary p-0 px-2 layout" id="navbar">
                <Navbar.Brand className="d-flex items-center" style={{ alignItems: 'center', gap: '10px' }} >
                    <Link to={`/${id}/${type}`}>
                        <img alt="logo" className="rounded-circle" src={storeInfo?.image ? env.API_LINK_STORAGE + storeInfo?.image : logo} width='50px' />
                    </Link>
                    {storeInfo?.rate && <ReactStars count={5} size={20} value={storeInfo?.rate} activeColor="var(--main-color)" />}
                </Navbar.Brand>
                <Navbar id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Nav>
                        <Link to={type ? `/${id}/${type}/cart` : `/${id}/cart`} className='position-relative p-0'>
                            <span className='position-absolute top-0 start-0 translate-middle px-2 rounded-circle text-light' style={{ backgroundColor: 'var(--second-color)', color: 'var(--main-color)' }} >{itemsCount}</span>
                            <i className="bi bi-basket fs-3" style={{ color: 'var(--main-color)' }}></i>
                        </Link>
                        <Link to={type ? `/${id}/${type}/store-details` : `/${id}/store-details`} className='p-0'><i className="bi bi-info-circle-fill fs-3 px-2" style={{ color: 'var(--main-color)' }}></i></Link>
                        <LanguageSwitcher />
                    </Nav>
                </Navbar>
            </Navbar>

            <Container style={{ minHeight: 'calc(100vh - 86px)' }} >
                {children}
            </Container>
            <div className='footer mx-auto'>
                {t('rights')}<a href='https://akwad4it.com' target="_blank" rel="noreferrer" ><img alt="logo" src={akwadLogo} width='20px' className="mx-1" /></a>
            </div>

            <div class="floating-container">
                <button class="floating-button" onClick={() => setModalShow(true)}>
                    <i class="bi bi-coin"></i>
                </button>
            </div>
            <CheckPointsModal
                show={modalShow}
                onHide={() => { setModalShow(false) }}
            />
        </>
    )
}

