/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectStoreInfo, selectStoreInfoInfoError, selectStoreInfoLoading } from '../slices/storeInfoSlice';
import { appService } from '../services/app.service';
import { useNavigate, useParams } from 'react-router-dom';
import ThemeProvider from '../providers/ThemeProvider';
import { ThemeColors } from './ThemeColors';
import i18n from './i18n';
import { getDataFromURL } from './helperFunctions';
import { getAll } from '../slices/cartSlice';
import FullScreenLoadingFirstLoad from '../components/Loading/FullScreenLoadingFirstLoad';

const ThemeManager = ({ children }) => {
    const { id } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let resFromURl = getDataFromURL(window.location.pathname)
    const storeInfo = useSelector(selectStoreInfo);
    const loading = useSelector(selectStoreInfoLoading);
    const error = useSelector(selectStoreInfoInfoError);
    let theme = null
    useEffect(() => {
        if (!storeInfo) {
            dispatch(appService.getStoreInfo(resFromURl));
        }
    }, []);

    if (!loading) {
        dispatch(getAll({ id: storeInfo?.id }))
        theme = {
            // fix it to work just remove "!"
            mainColor: storeInfo ? storeInfo.theme?.mainColor : "#fe4a8c",
            secondaryColor: storeInfo ? storeInfo.theme?.secondaryColor : "#7a32ff",
            dir: i18n.language === "ar" ? "rtl" : "ltr",
        };
    }

    return !loading ? <ThemeProvider theme={theme}><ThemeColors />
        {children}</ThemeProvider> : error ? navigate('/') : <FullScreenLoadingFirstLoad height={'100vh'} />
};

export default ThemeManager;