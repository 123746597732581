/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import * as formik from 'formik'
import * as yup from 'yup';
import "yup-phone-lite";
import { t } from 'i18next'
import Cookies from "js-cookie";
import { removeCart } from '../../slices/cartSlice'
import { appService } from '../../services/app.service'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FloatingLabel, Form as BootstrapForm, Table } from 'react-bootstrap'
import Toast from '../../components/Toast/Toast'
import { Spinner } from "react-bootstrap";
import country from "country-code-flag-phone-extension-json";
import Select from "react-select";
import env from '../../env';


export default function CheckPointsModal(props) {
  const { Formik } = formik
  const [selectedCountryCode, setSelectedCountryCode] = useState({})
  const [countries, setCountries] = useState([])
  const [pointsData, setPointsData] = useState([])
  const [selectedOptions, setSelectedOptions] = useState();
  const validationSchema = yup.object().shape({
    phone: yup.string().phone(selectedCountryCode.iso2, "invalid number").required(),
  })

  useEffect(() => {
    setCountries(country.all().rows)
  }, [countries])

  function handleSelect(data) {
    setSelectedOptions(data);
    const option = countries.find(opt => opt.id == data.id);
    setSelectedCountryCode(option)
  }

  const optionList = []
  countries?.forEach((code) => {
    optionList.push({
      id: code.id,
      value: code.dail_code,
      label: code?.dail_code + ' ' + code?.emoji
    })
  })

  const handleSubmit = async (values, { setSubmitting }) => {
    const data = {
      phone: parseInt(selectedCountryCode.dail_code) + values.phone.replace(/^0+/, ''),
    };
    let res = await appService.getPoints(data);
    if (res.status == 200) {
      if (res.data.data.length == 0) {
        Toast.SuccessToast(t('dontHavePoints'))
        props.onHide()
      } else {
        let x = []
        res.data.data.forEach((item) => {
          const y = {
            id: item.store_id,
            name: item?.name,
            image: item?.image,
            points: item?.total_points
          }
          x.push(y)
        })
        setPointsData(x)
      }
    } else {
      Toast.ErrorToast(res?.data.message ?? 'error')
    }
    setSubmitting(false)
  }

  return (
    pointsData.length > 0 ?
      <Modal {...props} size="lg" centered>
        <Modal.Header>
          <Modal.Title>{t('checkPoints')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className='mb-4'>{t('hasPoints')}</h5>
          <Table striped bordered hover size="sm">
            <tbody>
              {
                pointsData.map((item) => {
                  return (
                    <tr>
                      <td>
                        <div className='d-flex align-items-center justify-content-start p-1'>
                          <div style={{ width: '40px' }} className='me-3 rounded-circle'>
                            <img className='w-100 rounded-circle' src={env.API_LINK_STORAGE + item.image} />
                          </div>
                          {item.name}
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center justify-content-start'>
                          {item.points}
                        </div>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      :
      <Modal {...props} size="lg" centered>
        <Modal.Header>
          <Modal.Title>{t('checkPoints')}</Modal.Title>
        </Modal.Header>
        <Formik initialValues={{
          phone: '',
        }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ handleChange, values, touched, errors, isSubmitting }) => (
            <formik.Form>
              <Modal.Body>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-4 pe-0'>
                      <FloatingLabel controlId="country">
                        <Select
                          options={optionList}
                          onChange={handleSelect}
                          value={selectedOptions}
                          isSearchable={true}
                          placeholder='+964'
                        />
                        {/* <Form.Select onChange={handleOptionChange}>
                        {countries.map((item) => (
                          <option key={item.id} value={item.id}>{`${item.emoji}  ${item.dail_code}`}</option>
                        ))}
                      </Form.Select> */}
                      </FloatingLabel>
                    </div>
                    <div className='col-8 ps-0'>

                      <FloatingLabel controlId="phone" label={t('phone') + '*'} className={'mb-3 '}>
                        <BootstrapForm.Control type="text" name="phone" value={values.phone}
                          onChange={handleChange} isValid={touched.phone && !errors.phone} isInvalid={touched.phone && errors.phone} />
                        <BootstrapForm.Control.Feedback type="invalid">
                          {errors.phone}
                        </BootstrapForm.Control.Feedback>
                      </FloatingLabel>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                  {t('close')}
                </Button>
                <Button variant="primary" disabled={isSubmitting} type="submit">{isSubmitting ? <Spinner size="sm" /> : t('send')}</Button>
              </Modal.Footer>
            </formik.Form>
          )}
        </Formik>
      </Modal>
  );
}

