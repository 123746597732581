/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import * as formik from 'formik'
import * as yup from 'yup';
import "yup-phone-lite";
import { t } from 'i18next'
import Cookies from "js-cookie";
import { removeCart } from '../../slices/cartSlice'
import { appService } from '../../services/app.service'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FloatingLabel, Form as BootstrapForm } from 'react-bootstrap'
import Toast from '../../components/Toast/Toast'
import { Spinner } from "react-bootstrap";
import country from "country-code-flag-phone-extension-json";
import Select from "react-select";
import { selectStoreInfo } from '../../slices/storeInfoSlice';


export default function AddOrderModal(props) {
  const { id, type } = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { Formik } = formik
  const storeInfo = useSelector(selectStoreInfo);
  const { items } = useSelector((state) => state.cart)
  const [selectedCountryCode, setSelectedCountryCode] = useState({})
  const [countries, setCountries] = useState([])
  const [selectedOptions, setSelectedOptions] = useState();
  const [phone, setPhone] = useState();
  const [pointsLoading, setPointsLoading] = useState(false)
  const [points, setPoints] = useState(null)
  const validationSchema = yup.object().shape({
    customerName: yup.string().required(t('nameReq')),
    address: yup.string().when('type', {
      is: (x) => x == 'delivery',
      then: yup.string().required(t('addressReq')),
    }),
    points: yup.number().max((props.total * (storeInfo.pointsInfo.max_value / 100)) / storeInfo.pointsInfo.price),
    notes: yup.string().nullable(),
    phone: yup.string().phone(selectedCountryCode.iso2, "invalid number").required(),
  })

  useEffect(() => {
    setCountries(country.all().rows)
  }, [countries])

  function handleSelect(data) {
    setSelectedOptions(data);
    const option = countries.find(opt => opt.id == data.id);
    setSelectedCountryCode(option)
  }

  const optionList = []
  countries?.forEach((code) => {
    optionList.push({
      id: code.id,
      value: code.dail_code,
      label: code?.dail_code + '       ' + code?.emoji
    })
  })

  const handleSubmit = async (values, { setSubmitting }) => {
    let cartForOrder = []
    items.forEach(item => {
      cartForOrder.push({
        product_id: item.id,
        quantity: item.quantity,
        notes: item.note
      })
    });
    const data = {
      customer_name: values.customerName,
      phone: parseInt(selectedCountryCode.dail_code) + values.phone.replace(/^0+/, ''),
      name: type === 'delivery' ? null : type,
      notes: values.notes,
      store_id: id,
      items: cartForOrder,
      address: values.address,
      exchange_points: values.points,
    };
    let res = await appService.addOrder(data);
    if (res.status == 200) {
      dispatch(removeCart())
      Toast.SuccessToast(t('orderedSuccess'))
      type === 'delivery' ? navigate(`/${id}/delivery`) : navigate(`/${id}/${type}`)
      // fix it saraaaaaaaaaaaa
      // fix it saraaaaaaaaaaaa
      // fix it saraaaaaaaaaaaa
      // fix it saraaaaaaaaaaaa
      // fix it saraaaaaaaaaaaa
      // fix it saraaaaaaaaaaaa
      // fix it saraaaaaaaaaaaa
      Cookies.set("orderId", JSON.stringify(res.data.items[0].order_id));
      props.onHide()
    } else if (res.status == 407) {
      Toast.ErrorToast(t('dontHavePoints'))
    } else {
      Toast.ErrorToast(res?.data.message ?? 'unknown error')
    }
    setSubmitting(false)
  }

  const getPoints = async () => {
    setPointsLoading(true)
    const data = {
      phone: parseInt(selectedCountryCode.dail_code) + phone.replace(/^0+/, ''),
    };
    let res = await appService.getPoints(data);
    if (res.status == 200) {
      if (res.data.data.length == 0) {
        Toast.SuccessToast(t('dontHavePoints'))
      } else {
        var BreakException = {};
        try {

          res.data.data.forEach((item) => {
            const y = {
              id: item.store_id,
              name: item?.name,
              image: item?.image,
              points: item?.total_points
            }
            if (y.id == id) {
              setPoints(y.points)
              throw BreakException
            } else {
              setPoints(0)
            }
          })
        } catch {

        }

        if (points == 0) {
          Toast.SuccessToast(t('dontHavePoints'))
        }
      }
    } else {
      Toast.ErrorToast(res?.data.message ?? 'error')
    }
    setPointsLoading(false)
  }

  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header>
        <Modal.Title>{t('sendOrder')}</Modal.Title>
      </Modal.Header>
      <Formik initialValues={{
        customerName: '',
        phone: '',
        address: '',
        notes: '',
        points: ''
      }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ handleChange, values, touched, errors, isSubmitting }) => (
          <formik.Form>
            <Modal.Body>
              <div className='col-lg-12'>
                <FloatingLabel controlId="customerName" label={t('customerName') + '*'} className={'mb-3'}>
                  <BootstrapForm.Control type="text" name="customerName" value={values.customerName}
                    onChange={handleChange} isValid={touched.customerName && !errors.customerName} isInvalid={touched.customerName && errors.customerName} />
                  <BootstrapForm.Control.Feedback type="invalid">
                    {errors.customerName}
                  </BootstrapForm.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-4 pe-0'>
                    <FloatingLabel controlId="country">
                      <Select
                        options={optionList}
                        onChange={handleSelect}
                        value={selectedOptions}
                        isSearchable={true}
                        placeholder='+964'
                      />
                      {/* <Form.Select onChange={handleOptionChange}>
                        {countries.map((item) => (
                          <option key={item.id} value={item.id}>{`${item.emoji}  ${item.dail_code}`}</option>
                        ))}
                      </Form.Select> */}
                    </FloatingLabel>
                  </div>
                  <div className='col-8 ps-0'>

                    <FloatingLabel controlId="phone" label={t('phone') + '*'} className={'mb-3 '}>
                      <BootstrapForm.Control type="text" name="phone" value={values.phone}
                        onChange={(e) => {
                          handleChange(e)
                          setPhone(e.target.value)
                        }} isValid={touched.phone && !errors.phone} isInvalid={touched.phone && errors.phone} />
                      <BootstrapForm.Control.Feedback type="invalid">
                        {errors.phone}
                      </BootstrapForm.Control.Feedback>
                    </FloatingLabel>
                  </div>
                </div>
              </div>
              {
                type !== 'delivery' ? <></> :
                  <div className='col-lg-12'>
                    <FloatingLabel controlId="address" label={t('address')} className={'mb-3'}>
                      <BootstrapForm.Control as="textarea" style={{ height: '100px' }} type="text" name="address" value={values.address}
                        onChange={handleChange} isValid={touched.address && !errors.address} isInvalid={touched.address && errors.address} />
                      <BootstrapForm.Control.Feedback type="invalid">
                        {errors.address}
                      </BootstrapForm.Control.Feedback>
                    </FloatingLabel>
                  </div>
              }

              <div className='col-lg-12'>
                <FloatingLabel controlId="notes" label={t('note')} className={'mb-3'}>
                  <BootstrapForm.Control as="textarea" style={{ height: '100px' }} type="text" name="notes" placeholder="أدخل الملاحظات ان وجدت" value={values.notes}
                    onChange={handleChange} isValid={touched.notes && !errors.notes} isInvalid={touched.notes && errors.notes} />
                  <BootstrapForm.Control.Feedback type="invalid">
                    {errors.notes}
                  </BootstrapForm.Control.Feedback>
                </FloatingLabel>
              </div>
              {points > 0 ?
                <>
                  <div className='col-lg-12'>
                    <FloatingLabel controlId="points" label={t('pointsLabel') + '*'} className={'mb-3'}>
                      <BootstrapForm.Control type="text" name="points" value={values.points}
                        onChange={handleChange} isValid={touched.points && !errors.points} isInvalid={touched.points && errors.points} />
                      <BootstrapForm.Control.Feedback type="invalid">
                        {errors.points}
                      </BootstrapForm.Control.Feedback>
                    </FloatingLabel>
                    <div className='col-lg-12 d-flex justify-content-between align-items-center'>
                      <span>{`${t('urPoints')} : ${points} ${t('point')} = ${points * storeInfo.pointsInfo.price}`}</span>
                      <span>{`${t('uCanUse')} : ${(props.total * (storeInfo.pointsInfo.max_value / 100)) / storeInfo.pointsInfo.price}`}</span>
                    </div>
                  </div>
                </>
                : <></>
              }
            </Modal.Body>
            {
              values.phone.length > 0 ?
                <Modal.Footer className='d-flex align-items-center justify-content-between'>
                  <Button variant="warning" type="button" disabled={pointsLoading} onClick={async () => await getPoints()}>{pointsLoading ? <Spinner size="sm" /> : t('addCobon')}</Button>
                  <div>
                    <Button variant="secondary" className='me-2' onClick={props.onHide}>
                      {t('close')}
                    </Button>
                    <Button variant="primary" disabled={isSubmitting} type="submit">{isSubmitting ? <Spinner size="sm" /> : t('send')}</Button>
                  </div>
                </Modal.Footer>
                :
                <Modal.Footer >
                  <Button variant="secondary" className='me-2' onClick={props.onHide}>
                    {t('close')}
                  </Button>
                  <Button variant="primary" disabled={isSubmitting} type="submit">{isSubmitting ? <Spinner size="sm" /> : t('send')}</Button>
                </Modal.Footer>
            }
          </formik.Form>
        )}
      </Formik>
    </Modal>
  );
}

