/* eslint-disable array-callback-return */
import React, { useEffect } from 'react'
import MainLayout from '../components/layouts/MainLayout'
import Category from '../components/pageSections/Category'
import Product from '../components/pageSections/Products'
import Recommended from '../components/pageSections/Recommended'
import Search from '../components/pageSections/Search'
import { Container } from 'react-bootstrap'
import env from '../env'
import { useSelector } from 'react-redux'
import { selectStoreInfo } from '../slices/storeInfoSlice'

export default function HomePage() {
    const storeInfo = useSelector(selectStoreInfo);
    return (
        <MainLayout>
            {storeInfo?.accounts.length > 0 ?
                <Container className='mt-4 mb-0 d-flex align-items-center justify-content-start'>
                    {storeInfo?.accounts.map((item, index) => (
                        <a key={index} href={item.link} target='_blank' className='border rounded shadow me-3 mb-3 py-1 px-2 d-flex flex-column align-items-center justify-content-center item' rel="noreferrer">
                            <div className='rounded-circle mb-3' style={{ width: "50px" }}>
                                <img className='rounded-circle w-100' alt='logo' src={env.API_LINK_STORAGE + item.image} />
                            </div>
                            <span className='h6' style={{ color: "var(--main-color)" }}>{item.name}</span>
                        </a>
                    ))}
                </Container>
                : <></>
            }
            {/* <Search /> */}
            <Category />
            <Product />
            <Recommended />
        </MainLayout>
    )
}
