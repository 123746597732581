import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import storeLogo from '../assets/imgs/logo.png'
import rateLogo from '../assets/imgs/rate.png'
import RatesLayout from "../components/layouts/RatesLayout";
import { selectRate } from "../slices/rateSlice";
import { appService } from "../services/app.service";
import { useParams } from "react-router-dom";
import { getAll, removeOrder, setSelectedProduct, setSelectedProductRate } from '../slices/orderSlice'
import { selectStoreInfo, selectStoreInfoLoading } from "../slices/storeInfoSlice";
import env from "../env";
import Toast from "../components/Toast/Toast";
import { FloatingLabel, Form, Spinner, Form as BootstrapForm, Button } from "react-bootstrap";
import FullScreenLoading from "../components/Loading/FullScreenLoading";
import { cookiesService } from "../services/cookies.service";
import Cookies from "js-cookie";
import { t } from "i18next";

const RateStorePage = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const rateData = useSelector(selectRate);
  const storeInfo = useSelector(selectStoreInfo);
  const { items } = useSelector((state) => state.order);
  const [note, setNote] = useState('')
  const [storeStars, setStoreStars] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [servicesArray, setServicesArray] = useState([]);
  const [productsArray, setProductsArray] = useState([]);
  const cartArray = cookiesService.get("order")
    ? cookiesService.get("order")
    : [];
  const orderId = cookiesService.get("orderId")
    ? cookiesService.get("orderId")
    : [];

  const handleServiceRate = (item, newRating) => {
    const updatedService = {
      serviceStars: newRating,
      type: item[1],
    };
    setServicesArray((prevServicesArray) => {
      const index = prevServicesArray.findIndex(
        (service) => service.type === item
      );
      if (index !== -1) {
        prevServicesArray[index] = updatedService;
        return [...prevServicesArray];
      } else {
        return [...prevServicesArray, updatedService];
      }
    });
  };

  const handleProductRate = (id, newRating) => {
    const updatedProduct = {
      productStars: newRating,
      id: id,
    };

    setProductsArray((prevProductsArray) => {
      const index = prevProductsArray.findIndex((product) => product.id === id);

      if (index !== -1) {
        prevProductsArray[index] = updatedProduct;
        return [...prevProductsArray];
      } else {
        return [...prevProductsArray, updatedProduct];
      }
    });
  };


  const handleRate = async () => {
    setIsLoading(true);
    let products = [];
    let services = [];
    productsArray.forEach((item) => {
      products.push({
        stars: item.productStars,
        product_id: item.id,
      });
    });

    servicesArray.forEach((item) => {
      services.push({
        stars: item.serviceStars,
        type: item.type,
      });
    })
    const data = {
      stars: storeStars,
      notes: note,
      store_id: id,
      order_id: orderId,
      products: products,
      services: services,
    };

    if (data.stars > 0 && productsArray?.length === cartArray?.length) {
      let res = await appService.addRate(data);
      if (res.success) {
        Toast.SuccessToast(t('rateDone'));
        dispatch(removeOrder())
        Cookies.remove("orderId");
      }
    } else {
      Toast.ErrorToast(t('rateError'));
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (id) {
      dispatch(appService.getStoreRateServices(id));
    }
    dispatch(getAll({ id: storeInfo?.id }))
  }, [id]);


  return (
    cartArray.length > 0 ?
      <RatesLayout>
        <Card >
          <Card.Img variant="top" src={rateLogo} style={{ width: 'fit-content', margin: 'auto' }} />
          <Card.Title className='text-center'>{t('reviewUs')}</Card.Title>
          <Card.Body className='d-flex' style={{ alignItems: 'end', justifyContent: 'space-evenly' }}>

            <Card.Text>{storeInfo?.name}</Card.Text>
            <Card.Text>
              <ReactStars
                count={5}
                onChange={(e) => { setStoreStars(parseInt(e)); }}
                size={24}
                activeColor='var(--main-color)'
              />
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush" >
            {items.map((item, idx) =>
              <ListGroup.Item key={idx} className='d-flex ' style={{ alignItems: 'center', justifyContent: 'space-evenly' }}>{item.name}
                <ReactStars
                  count={5}
                  onChange={(value) => handleProductRate(item.id, value)}
                  size={24}
                  activeColor='var(--main-color)'
                />
              </ListGroup.Item>
            )}
            {/* map */}
            {rateData?.data ? Object.entries(rateData?.data)?.map((item, idx) =>
              <ListGroup.Item key={idx} className='d-flex ' style={{ alignItems: 'center', justifyContent: 'space-evenly' }}>{item}
                <ReactStars
                  count={5}
                  onChange={(value) =>
                    handleServiceRate(item, value)
                  }
                  size={24}
                  activeColor='var(--main-color)'
                />
              </ListGroup.Item>
            ) : <></>}
          </ListGroup>
          <FloatingLabel controlId="note" label={t('note')} className={'my-3'} style={{ width: '90%', margin: 'auto' }}>
            <BootstrapForm.Control as="textarea" type="text" name="note" placeholder={t('note')} style={{ height: '100px' }}
              onChange={(e) => {
                setNote(e.target.value);
              }} />
          </FloatingLabel>
        </Card>

        <button
          className="btn btn-primary d-block mx-auto my-3 text-light"
          variant="primary"
          type="button"
          onClick={handleRate}
        >
          {isLoading ? <Spinner size="sm" /> : t('send')}
        </button>

      </RatesLayout> :
      <section id="ratesDoneSection">
        <div className='text-center'>

          <img src={storeLogo} alt="" width='150px' height='150px' className="ratesDone-img" />

        </div>
        <h2 >
          {t('thanks')}

          <br />
          <span style={{ color: 'var(--main-color)' }}> ORDIT </span>
          <br />
          {t('glads')}
        </h2>
      </section>
  )
};

export default RateStorePage;
